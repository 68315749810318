define("client/helpers/plus-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.plusOne = plusOne;
  _exports.default = void 0;

  function plusOne(params, hash) {
    var temp = parseInt(params[0]);
    return temp += 1;
  }

  var _default = Ember.Helper.helper(plusOne);

  _exports.default = _default;
});