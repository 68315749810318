define("client/models/logintracker", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    ip_address: _emberData.default.attr('string'),
    login_time: _emberData.default.attr('date'),
    logout_time: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    }),
    updatedAt: _emberData.default.attr('date', {
      defaultValue: function defaultValue() {
        return new Date();
      }
    })
  });

  _exports.default = _default;
});