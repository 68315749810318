define("client/models/assetconditionmonitor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    assetconditionmonitorid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    loweraction: _emberData.default.attr('number'),
    lowerwarning: _emberData.default.attr('number'),
    upperaction: _emberData.default.attr('number'),
    upperwarning: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    assetnum: _emberData.default.belongsTo("asset", {
      async: true
    }),
    assetmeternum: _emberData.default.belongsTo("assetmeter", {
      async: true
    }),
    sitenum: _emberData.default.belongsTo("site", {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});