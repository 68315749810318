define("client/helpers/eq2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var eq2 = function eq2(params) {
    return params[0] == params[1];
  };

  var _default = Ember.Helper.helper(eq2);

  _exports.default = _default;
});