define("client/components/root", ["exports", "ember-composability-tools/components/root"], function (_exports, _root) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _root.default;
    }
  });
});