define("client/pods/profile/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y35axSFY",
    "block": "{\"symbols\":[],\"statements\":[[1,[22,\"outlet\"],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"col my-5 pt-5 px-0\"],[8],[0,\"\\n    \"],[1,[28,\"component\",[\"w3o-users-roles/user-profile\"],[[\"model\",\"user\",\"countryList\",\"userDetail\",\"secretQuestionModel\",\"oldquestion\",\"setStatus\",\"disabled\",\"isUploading\",\"profilepic\",\"dataURI\",\"canReset\",\"isEditing\",\"disabledSaveButton\",\"upload\",\"saveEdit\",\"getInfo\",\"changeStatus\",\"changePassword\",\"changeQuestion\",\"invalidateSession\"],[[24,[\"model\"]],[24,[\"model\"]],[24,[\"countryList\"]],[24,[\"userDetail\"]],[24,[\"secretQuestionModel\"]],[24,[\"oldquestion\"]],[24,[\"setStatus\"]],[24,[\"disabled\"]],[24,[\"isUploading\"]],[24,[\"profilepic\"]],[24,[\"dataURI\"]],[24,[\"canReset\"]],[24,[\"isEditing\"]],[24,[\"disabledSaveButton\"]],\"ppupload\",[28,\"route-action\",[\"saveEdit\"],null],[28,\"route-action\",[\"getInfo\"],null],[28,\"route-action\",[\"changeStatus\"],null],[28,\"route-action\",[\"changePassword\"],null],[28,\"route-action\",[\"changeQuestion\"],null],[28,\"route-action\",[\"invalidateSession\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/profile/template.hbs"
    }
  });

  _exports.default = _default;
});