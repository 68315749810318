define("client/models/clientnotified", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    notifiedby: _emberData.default.attr('string'),
    notifieddate: _emberData.default.attr('string'),
    notifiedemail: _emberData.default.attr('string'),
    sendtoname: _emberData.default.attr('string'),
    sendtoemail: _emberData.default.attr('string'),
    bodyemail: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    srstatus: _emberData.default.attr('string'),
    // relation
    servicedesk_category: _emberData.default.belongsTo('servicecategory', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});