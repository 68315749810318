define("client/transforms/array", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized === null || serialized === undefined) {
        return [];
      }

      if (Ember.typeOf(serialized) === "array") {
        return serialized;
      } else if (Ember.typeOf(serialized) === "string") {
        var temp = serialized.replace(/'/g, '"');
        temp = JSON.parse(temp);
        return temp;
      } else {
        return [];
      }
    },
    serialize: function serialize(deserialized) {
      var type = Ember.typeOf(deserialized);

      if (type === 'array') {
        return deserialized;
      } else if (type === 'string') {
        return deserialized.split(',').map(function (item) {
          return $.trim(item);
        });
      } else {
        return [];
      }
    }
  });

  _exports.default = _default;
});