define("client/models/sparepart", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    issuedqty: _emberData.default.attr('number'),
    qty: _emberData.default.attr('number'),
    totalusage: _emberData.default.attr('number'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    //sitenum: DS.belongsTo('site', { async: true}),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    assetnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});