define("client/models/inventoryusage", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventoryusageid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    usagetype: _emberData.default.attr('string'),
    usage: _emberData.default.attr('string'),
    refid: _emberData.default.attr('number'),
    shipping: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    transactiondate: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    storeloc: _emberData.default.belongsTo('location', {
      async: true
    }),
    responsibleby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});