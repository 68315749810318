define("client/helpers/column-length", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.columnLength = columnLength;
  _exports.default = void 0;

  function columnLength(params, hash) {
    var count = parseInt(params[0]);
    return 12 / count;
  }

  var _default = Ember.Helper.helper(columnLength);

  _exports.default = _default;
});