define("client/pods/components/w3office-forms/filter-modal-column/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    placeholder: 'Enter Value',
    currentType: null,
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var selectedFilterColumn = this.get("filterColumns").find(function (filterColumn) {
        return filterColumn.columnName === _this.get("filter.columnName");
      });

      if (this.get("filter.placeholder")) {
        this.set("placeholder", this.get("filter.placeholder"));
      }

      this.set("currentColumnName", this.get("filter.columnName"));
      this.set("operatorOptions", selectedFilterColumn.operator);
      this.setInputType({
        filterType: this.get("filter.type"),
        operator: this.get("filter.operator")
      });
    },
    setInputType: function setInputType(params) {
      var filterType = params.filterType,
          operator = params.operator;

      if (["string", "relation"].includes(filterType)) {
        this.set("inputType", "text");
      } else {
        if (operator === "=") {
          this.set("inputType", "text");
        } else {
          this.set("inputType", "number");
        }
      }
    },
    actions: {
      deleteFilter: function deleteFilter(filter) {
        this.deleteFilter(filter);
      },
      filterColumnChanged: function filterColumnChanged(columnName) {
        var selectedFilterColumn = this.get("filterColumns").find(function (filterColumn) {
          return filterColumn.columnName === columnName;
        });

        if (selectedFilterColumn.placeholder) {
          this.set("placeholder", selectedFilterColumn.placeholder);
        } else {
          this.set("placeholder", "Enter Value");
        }
        /*Reset filter.value*/


        if (this.get("currentColumnName") !== columnName) {
          this.set("filter.value", null);
          this.set("filter.value2", null);
          this.set("currentColumnName", columnName);
        }
        /*Handle filter.operator*/


        if (Ember.isEmpty(selectedFilterColumn.operator)) {
          this.set("filter.operator", null);
        } else {
          this.set("filter.operator", selectedFilterColumn.operator[0]);
        }

        this.set("filter.columnName", columnName);
        this.set("filter.label", selectedFilterColumn.label);
        this.set("filter.type", selectedFilterColumn.type);
        this.set("operatorOptions", selectedFilterColumn.operator);
        this.setInputType({
          filterType: selectedFilterColumn.type,
          operator: selectedFilterColumn.operator[0]
        });

        if (selectedFilterColumn.relationColumnName) {
          this.set("filter.relationColumnName", selectedFilterColumn.relationColumnName);
        }
      },
      filterOperatorChanged: function filterOperatorChanged(operator) {
        var _this2 = this;

        var selectedFilterColumn = this.get("filterColumns").find(function (filterColumn) {
          return filterColumn.columnName === _this2.get("filter.columnName");
        });
        this.setInputType({
          filterType: selectedFilterColumn.type,
          operator: operator
        });
        this.set("filter.operator", operator);
      },
      valueChanged: function valueChanged(selectedDates) {
        console.log(selectedDates);
        this.set("filter.value", selectedDates[0].toISOString());
      },
      value2Changed: function value2Changed(selectedDates) {
        console.log(selectedDates);
        this.set("filter.value2", selectedDates[0].toISOString());
      }
    }
  });

  _exports.default = _default;
});