define("client/pods/components/w3office-forms/popup-list-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    activeFilterColumn: '',
    activeFilterValue: '',
    sortDescFlag: false,
    init: function init() {
      this._super();
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      // let el = document.querySelector('.customScrollbar');
      // Ps.initialize(el);
      if (this.get('listHeader')) {
        this.get('listHeader').forEach(function (header) {
          if (header.isActive) {
            _this.set('activeFilterColumn', header.columnName);
          }
        });
      }
    },
    actions: {
      nextList: function nextList() {
        this.nextList();
      },
      prevList: function prevList() {
        this.prevList();
      },
      saveEntry: function saveEntry(entry) {
        if (this.get('multipleSelect')) {
          this.pushToArrayOfSelected(entry);
        } else {
          (0, _jquery.default)('.modal').modal('hide');
          this.saveEntry(entry);
        }
      },
      filterEntries: function filterEntries() {
        if (this.get('activeFilterColumn') !== 'none' && !Ember.isEmpty(this.get('activeFilterValue'))) {
          this.filterEntries();
        }
      },
      sortEntries: function sortEntries(header) {
        if (this.get('sortDescFlag')) {
          this.get('conditionContainer')['sort'] = header + ' DESC';
        } else {
          this.get('conditionContainer')['sort'] = header + ' ASC';
        }

        this.setProperties({
          lastSortParam: header,
          sortDescFlag: !this.get('sortDescFlag')
        });
        this.sortEntries(header);
      },
      resetList: function resetList() {
        this.resetList();
      },
      changeEntryLimit: function changeEntryLimit(limit) {
        this.changeEntryLimit(limit);
      }
    }
  });

  _exports.default = _default;
});