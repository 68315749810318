define("client/models/inventoryusagedetailasset", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    assetid: _emberData.default.attr('string'),
    assetname: _emberData.default.attr('string'),
    startdescription: _emberData.default.attr('string'),
    //manual
    warrantyexpireddate: _emberData.default.attr('date'),
    //manual
    installdate: _emberData.default.attr('date'),
    //manual
    barcode: _emberData.default.attr('string'),
    productid: _emberData.default.attr('string'),
    assetno: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    memo: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    assetparentnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    assettypenum: _emberData.default.belongsTo('assettype', {
      async: true
    }),
    classificationnum: _emberData.default.belongsTo('classification', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    vendornum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    manufacturernum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    inventoryserialnum: _emberData.default.belongsTo('inventoryserial', {
      async: true
    }),
    invusagedetailnum: _emberData.default.belongsTo('inventoryusagedetail', {
      async: true
    }),
    failureclassnum: _emberData.default.belongsTo('failureclass', {
      async: true
    }),
    assetgroupnum: _emberData.default.belongsTo('assetgroup', {
      async: true
    }),
    assetdowntimecodenum: _emberData.default.belongsTo('assetdowntimecode', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});