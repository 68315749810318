define("client/helpers/number-less", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberLess = numberLess;
  _exports.default = void 0;

  function numberLess(params
  /*, hash*/
  ) {
    if (parseInt(params[0]) < parseInt(params[1])) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(numberLess);

  _exports.default = _default;
});