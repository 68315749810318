define("client/models/inventorystocktakedetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventoryserialid: _emberData.default.attr('string'),
    //char 20
    balanceqty: _emberData.default.attr('number'),
    //decimal 20 6
    physicalqty: _emberData.default.attr('number'),
    //decimal 20 6
    isdelete: _emberData.default.attr('boolean'),
    statusqty: _emberData.default.attr('string'),
    // MATCH or NOT MATCH
    isadjustplus: _emberData.default.attr('boolean'),
    reason: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    value: _emberData.default.attr('number'),
    // adjustqty: DS
    // actualadjustqty: 
    // 
    //relation
    invstocktakenum: _emberData.default.belongsTo('inventorystocktake', {
      async: true
    }),
    invadjustmentdetailnum: _emberData.default.belongsTo('inventoryadjustmentdetail', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});