define("client/components/loading-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    size: 250,
    color: 'white',
    loadingSvgSize: Ember.computed.alias('size'),
    loadingSvgColor: Ember.computed.alias('color')
  });

  _exports.default = _default;
});