define("client/models/inventoryadjustmentdetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventorylotid: _emberData.default.attr('string'),
    // char(15)
    qtyadjust: _emberData.default.attr('number'),
    //decimal 20,6
    debitcredit: _emberData.default.attr('string'),
    // char(1)
    adjustmentreason: _emberData.default.attr('string'),
    //varchar(50)
    adjustmenttype: _emberData.default.attr('string'),
    //char(10), regular stocktake
    multiserialflag: _emberData.default.attr('boolean'),
    value: _emberData.default.attr('number'),
    // money
    reason: _emberData.default.attr('string'),
    // varchar(100)
    selectvendor: _emberData.default.attr('string'),
    selectmanufacturer: _emberData.default.attr('string'),
    lotalreadyexist: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    inventoryadjustmentnum: _emberData.default.belongsTo('inventoryadjustment', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    vendornum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    manufacturernum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    invstocktakedetailnum: _emberData.default.belongsTo('inventorystocktakedetail', {
      async: true
    }) // shipmentdetailnum: DS.belongsTo('shipmentdetail', {async: true})

  });

  _exports.default = _default;
});