define("client/models/woservice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    reservedqty: _emberData.default.attr('number'),
    usedqty: _emberData.default.attr('number'),
    unitcost: _emberData.default.attr('number'),
    linecost: _emberData.default.attr('number'),
    usedunitcost: _emberData.default.attr('number'),
    usedlinecost: _emberData.default.attr('number'),
    isplan: _emberData.default.attr('boolean'),
    isactual: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    workordernum: _emberData.default.belongsTo("workorder", {
      async: true
    }),
    itemnum: _emberData.default.belongsTo("item", {
      async: true
    }),
    uomnum: _emberData.default.belongsTo("uom", {
      async: true
    }),
    jobplanservicenum: _emberData.default.belongsTo("jobplanservice", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});