define("client/helpers/break-lines", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.breakLines = breakLines;
  _exports.default = void 0;

  function breakLines(params
  /*, hash*/
  ) {
    var text = Ember.Handlebars.Utils.escapeExpression(params[0]);
    text = text.replace('\n', '<br />');
    return new Ember.String.htmlSafe(text);
  }

  var _default = Ember.Helper.helper(breakLines);

  _exports.default = _default;
});