define("client/models/servicecategory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    categoryname: _emberData.default.attr('string'),
    //relation
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    servicedesk_category: _emberData.default.belongsTo('servicecategory', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('servicecategory', {
      inverse: 'servicedesk_category',
      async: true
    }),
    isdelete: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});