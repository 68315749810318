define("client/models/goodreceived", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    goodreceiveid: _emberData.default.attr('string'),
    goodreceivedate: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    POnumber: _emberData.default.attr('string'),
    POdate: _emberData.default.attr('date'),
    approvedate: _emberData.default.attr('date'),
    type: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    approvee: _emberData.default.belongsTo('user', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    vendornum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    })
  });

  _exports.default = _default;
});