define("client/pods/components/w3office-forms/flash-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KkCXuXeG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"status\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"succeed\"]]],null,{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flash-messsage success\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"message\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\t\"],[7,\"div\",true],[10,\"class\",\"flash-messsage unsuccessful\"],[8],[0,\"\\n\\t\\t\\t\"],[1,[22,\"message\"],false],[0,\"\\n\\t\\t\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/components/w3office-forms/flash-message/template.hbs"
    }
  });

  _exports.default = _default;
});