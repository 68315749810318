define("client/initializers/form-global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var formContainer = Ember.Object.create({
      parentEntry: null,
      parentEntryStack: [],
      parentIndex: '',
      fieldHolder: null,
      componentHolder: null,
      sessionHolder: null,
      relationHolder: 1,
      userHolder: null,
      optmm: true,
      //For Entry Detail Purpose
      fromEntryDetail: false,
      formParentID: null,
      formParent: null,
      childRelation: null,
      //For Dummy Form
      parentEntryOther: null,
      parentDummy: null,
      accessType: null,
      resourceAccess: null,
      organization: null,
      company: null,
      user: null,
      resourceValidationFinished: Ember.RSVP.defer(),

      /*languages based on ISO 639-1 codes*/
      languages: [{
        value: 'en',
        name: 'English'
      }, {
        value: 'id',
        name: 'Indonesia'
      }, {
        value: 'de',
        name: 'Germany'
      }, {
        value: 'ko',
        name: 'Korea'
      }],
      language: 'en'
    });
    var formComponentList = [];
    application.register('formContainer:main', formContainer, {
      instantiate: false
    });
    application.register('formComponentList:main', formComponentList, {
      instantiate: false
    });
    application.inject('route', 'formContainer', 'formContainer:main');
    application.inject('template', 'formContainer', 'formContainer:main');
    application.inject('component', 'formContainer', 'formContainer:main');
    application.inject('route', 'formComponentList', 'formComponentList:main');
    application.inject('component', 'formComponentList', 'formComponentList:main');
  }

  var _default = {
    name: 'form-global',
    initialize: initialize
  };
  _exports.default = _default;
});