define("client/models/inventorybalance", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    onhandqty: _emberData.default.attr('number'),
    //Decimal (20,6)
    reservedqty: _emberData.default.attr('number'),
    //Decimal (20,6)
    qtyavail: _emberData.default.attr('number'),
    //Decimal (20,6)
    isserialtype: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    inventoryitemnum: _emberData.default.belongsTo('inventory', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});