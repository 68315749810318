define("client/models/assetmeterread", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    readingdate: _emberData.default.attr("date"),
    newreading: _emberData.default.attr("number"),
    //decimal (20,6)
    lastreading: _emberData.default.attr("number"),
    //decimal(20,6)
    observation: _emberData.default.attr("string"),
    delta: _emberData.default.attr("number"),
    //decimal(20,6)
    readingtype: _emberData.default.attr("string"),
    metertype: _emberData.default.attr("string"),
    reason: _emberData.default.attr("string"),
    startmeasure: _emberData.default.attr("number"),
    //decimal(20,6)
    endmeasure: _emberData.default.attr("number"),
    //decimal(20,6)
    enterdate: _emberData.default.attr("date"),
    needcheck: _emberData.default.attr("boolean"),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    inspector: _emberData.default.belongsTo("employee", {
      async: true
    }),
    //bigint
    createdBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    //bigint
    updatedBy: _emberData.default.belongsTo("user", {
      async: true
    }),
    //bigint
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    sitenum: _emberData.default.belongsTo("site", {
      async: true
    }),
    assetnum: _emberData.default.belongsTo("asset", {
      async: true
    }),
    locationnum: _emberData.default.belongsTo("location", {
      async: true
    }),
    uomnum: _emberData.default.belongsTo("uom", {
      async: true
    }),
    assetmeternum: _emberData.default.belongsTo("assetmeter", {
      async: true
    })
  });

  _exports.default = _default;
});