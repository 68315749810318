define("client/pods/components/w3office-forms/advanced-components/popup-list/component-field/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row', 'mx-0'],
    classNameBindings: ['isSkeleton:skeleton'],
    _popuplistLabel: Ember.computed('elementId', function () {
      return "popuplist-".concat(this.get('elementId'));
    }),
    didRender: function didRender() {
      if (this.get('currentTheme')) {
        (0, _jquery.default)("#".concat(this.get("_popuplistLabel"))).css({
          "color": this.get('currentTheme').valueFontColor,
          "font-size": this.get('currentTheme').valueFontSize
        });
      }
    },
    actions: {
      showList: function showList() {
        this.showList();
      },
      deleteEntry: function deleteEntry() {
        this.deleteEntry();
      }
    }
  });

  _exports.default = _default;
});