define("client/models/womaterial", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    quantity: _emberData.default.attr('number'),
    reservedqty: _emberData.default.attr('number'),
    usedqty: _emberData.default.attr('number'),
    returnqty: _emberData.default.attr('number'),
    preparedqty: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    unitcost: _emberData.default.attr('number'),
    linecost: _emberData.default.attr('number'),
    usedunitcost: _emberData.default.attr('number'),
    usedlinecost: _emberData.default.attr('number'),
    completereturn: _emberData.default.attr('boolean'),
    needreturn: _emberData.default.attr('boolean'),
    isplan: _emberData.default.attr('boolean'),
    isactual: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    itemnum: _emberData.default.belongsTo("item", {
      async: true
    }),
    workordernum: _emberData.default.belongsTo("workorder", {
      async: true
    }),
    locationnum: _emberData.default.belongsTo("location", {
      async: true
    }),
    racknum: _emberData.default.belongsTo("rack", {
      async: true
    }),
    binnum: _emberData.default.belongsTo("bin", {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo("inventorylot", {
      async: true
    }),
    uomnum: _emberData.default.belongsTo("uom", {
      async: true
    }),
    jobplanmaterialnum: _emberData.default.belongsTo("jobplanmaterial", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});