define("client/models/assetdisposal", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    assetdisposalid: _emberData.default.attr('string'),
    reason: _emberData.default.attr('string'),
    salvagevalue: _emberData.default.attr('number'),
    reference: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    // [enterdate] [datetime] NULL,
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    assetnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    salvagecurrencynum: _emberData.default.belongsTo('currency', {
      async: true
    }),
    lastsite: _emberData.default.belongsTo('site', {
      async: true
    }),
    lastlocation: _emberData.default.belongsTo('location', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    // 	[enterby] [bigint] NULL,
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }) // [disposaldate] [datetime] NULL,
    // [approveby] [bigint] NULL,
    // [executedate] [datetime] NULL,
    // [executeby] [bigint] NULL,
    // [assetchangenum] [bigint] NULL,
    // [reference] [varchar](100) NULL,

  });

  _exports.default = _default;
});