define("client/models/jobplanrevision", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    revision: _emberData.default.attr('number'),
    approveddate: _emberData.default.attr('date'),
    memo: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    jobplannum: _emberData.default.belongsTo('jobplan', {
      async: true
    }),
    approvedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});