define("client/models/inventoryadjustment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    adjustmenttype: _emberData.default.attr('string'),
    // char(20) , regular stocktake
    inventoryadjustmentid: _emberData.default.attr('string'),
    // char(15)
    enterdate: _emberData.default.attr('date'),
    description: _emberData.default.attr('string'),
    //varchar(200)
    approveddate: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    //enter approve complete cancel revision
    adjustmentdate: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    approvedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    adjustmentby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    invstocktakenum: _emberData.default.belongsTo('inventorystocktake', {
      async: true
    }) //shipmentnum			: DS.belongsTo('shipment'),

  });

  _exports.default = _default;
});