define("client/helpers/sanitize-css", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sanitizeCss = sanitizeCss;
  _exports.default = void 0;

  function sanitizeCss(params
  /*, hash*/
  ) {
    return Ember.String.htmlSafe(params[1] + params[0] + '; ' + params[2] + ';');
  }

  var _default = Ember.Helper.helper(sanitizeCss);

  _exports.default = _default;
});