define("client/models/masterpm", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    masterpmid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    // lastchangedate: DS.attr('date'),
    // startcountdate: DS.attr('date'),
    // timefrequent: DS.attr('number'),
    // alertleadday: DS.attr('number'),
    // nextdate: DS.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    masterpmjobplans: _emberData.default.hasMany('masterpmjobplan', {
      async: true
    }) // timeunit: DS.belongsTo('uom', { async: true})

  });

  _exports.default = _default;
});