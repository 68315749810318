define("client/helpers/last-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.lastColumn = lastColumn;
  _exports.default = void 0;

  function lastColumn(params
  /*, hash*/
  ) {
    if (parseInt(params[0]) === parseInt(params[1]) - 1) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(lastColumn);

  _exports.default = _default;
});