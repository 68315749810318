define("client/models/inventorybalancereserve", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    qty: _emberData.default.attr('number'),
    reservedqty: _emberData.default.attr('number'),
    sourceparenttype: _emberData.default.attr('string'),
    sourcetype: _emberData.default.attr('string'),
    sourcenum: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    //relationships
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    inventoryitemnum: _emberData.default.belongsTo('inventory', {
      async: true
    }),
    inventorybalancenum: _emberData.default.belongsTo('inventorybalance', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});