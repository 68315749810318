define("client/models/womaterialserial", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    //relationships
    companynum: _emberData.default.belongsTo('company'),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    inventoryserialnum: _emberData.default.belongsTo('inventoryserial', {
      async: true
    }),
    womaterialnum: _emberData.default.belongsTo('womaterial', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});