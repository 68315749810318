define("client/models/pmmeter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    reading: _emberData.default.attr('number'),
    alert: _emberData.default.attr('number'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    prevmaintainnum: _emberData.default.belongsTo("prevmaintain", {
      async: true
    }),
    assetmeternum: _emberData.default.belongsTo("assetmeter", {
      async: true
    }),
    metergroupnum: _emberData.default.belongsTo("metergroup", {
      async: true
    }),
    assetnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});