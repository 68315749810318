define("client/models/serviceresponperson", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    remark: _emberData.default.attr('string'),
    // relation
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    employeenum: _emberData.default.belongsTo('employee', {
      async: true
    }),
    // servicedesk_srresponsibility: DS.belongsTo('serviceresponsibility'),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    servicedesk_category: _emberData.default.belongsTo('servicecategory', {
      async: true
    }),
    servicedesk_type: _emberData.default.belongsTo('servicetype', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});