define("client/models/meter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    meterid: _emberData.default.attr('string'),
    metername: _emberData.default.attr('string'),
    metertype: _emberData.default.attr('string'),
    readingtype: _emberData.default.attr('string'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('employee', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('employee', {
      async: true
    })
  });

  _exports.default = _default;
});