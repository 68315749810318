define("client/helpers/first-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.sameRow = sameRow;
  _exports.default = void 0;

  function sameRow(params
  /*, hash*/
  ) {
    // if(params[0] === this.rowHolder)
    // {
    // 	this.rowHolder =  params[0];
    // 	return true;
    // }
    // else
    // {
    // 	this.rowHolder = params[0];
    // 	return false;
    // }
    if (parseInt(params[0]) === 0) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(sameRow);

  _exports.default = _default;
});