define("client/models/inventorystocktake", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventorystocktakeid: _emberData.default.attr('string'),
    //char 15
    balancecountdate: _emberData.default.attr('date'),
    balancecountby: _emberData.default.attr('string'),
    stocktakedate: _emberData.default.attr('date'),
    //datetime
    freezeqtydate: _emberData.default.attr('date'),
    prepareddate: _emberData.default.attr('date'),
    //datetime
    adjustapprovaldate: _emberData.default.attr('date'),
    //datetime
    approveddate: _emberData.default.attr('date'),
    //datetime
    status: _emberData.default.attr('string'),
    //char 15
    isdelete: _emberData.default.attr('boolean'),
    condition: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    //voidreason: DS.attr('string'),
    //relation
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    invadjustmentnum: _emberData.default.belongsTo('inventoryadjustment', {
      async: true
    }),
    preparedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    adjustapprovalby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    approvedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});