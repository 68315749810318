define("client/transforms/dateiso", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        return (0, _moment.default)(serialized).toDate();
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (deserialized) {
        return (0, _moment.default)(deserialized).format();
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});