define("client/models/inventorystocktakerackbinresp", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    status: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    responsibleBy: _emberData.default.belongsTo('employee', {
      async: true
    }),
    invstocktakenum: _emberData.default.belongsTo('inventorystocktake', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});