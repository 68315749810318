define("client/models/jobplan", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    jobplanid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    instruction: _emberData.default.attr('string'),
    revision: _emberData.default.attr('number'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    sequence: _emberData.default.attr('number'),
    //Sequence for child job plan
    duration: _emberData.default.attr('number'),
    isdowntime: _emberData.default.attr('boolean'),
    istask: _emberData.default.attr('boolean'),
    // jptype: DS.attr('string'),
    // isapproved: DS.attr('boolean'),
    status: _emberData.default.attr('string'),
    memo: _emberData.default.attr('string'),
    taskid: _emberData.default.attr('string'),
    //Task Dependency
    dependencytype: _emberData.default.attr('string'),
    //relationship
    dependenceto: _emberData.default.belongsTo("jobplan", {
      async: true
    }),
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    approvedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    uomcategory: _emberData.default.belongsTo('uomcategory', {
      async: true
    }),
    durationuom: _emberData.default.belongsTo('uom', {
      async: true
    }),
    jobplanprioritynum: _emberData.default.belongsTo("workorderpriority", {
      async: true
    }),
    workordertype: _emberData.default.belongsTo("workordertype", {
      async: true
    }),
    jobplanmaterials: _emberData.default.hasMany('jobplanmaterial', {
      async: true
    }),
    jobplanservices: _emberData.default.hasMany('jobplanservice', {
      async: true
    }),
    jobplantools: _emberData.default.hasMany('jobplantool', {
      async: true
    }),
    jobplanparentnum: _emberData.default.belongsTo("jobplan", {
      inverse: 'task',
      async: true
    }),
    task: _emberData.default.hasMany("jobplan", {
      inverse: 'jobplanparentnum',
      async: true
    })
  });

  _exports.default = _default;
});