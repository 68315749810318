define("client/pods/components/general-component/base-toplevel-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    primaryClassName: null,
    classNames: ['w3o-component'],
    classNameBindings: ['_primaryClassNameString'],
    _primaryClassNameString: Ember.computed('primaryClassName', function () {
      return "w3o-".concat(this.get('primaryClassName'));
    })
  });

  _exports.default = _default;
});