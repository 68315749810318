define("client/models/assetmeter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sequence: _emberData.default.attr('number'),
    startmeasure: _emberData.default.attr('number'),
    endmeasure: _emberData.default.attr('number'),
    lastreadingdate: _emberData.default.attr('date'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    meterid: _emberData.default.attr('string'),
    metername: _emberData.default.attr('string'),
    //relationship
    assetnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    metergroupnum: _emberData.default.belongsTo('metergroup', {
      async: true
    }),
    meternum: _emberData.default.belongsTo('meter', {
      async: true
    }),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }) //lastreadinginspector

  });

  _exports.default = _default;
});