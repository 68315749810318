define("client/initializers/global-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    /*
    Initialize appSetting Object
    */
    var appSetting = {
      appId: 1,
      appName: 'User & Role Management',
      resourceAccessEnabled: false
    };
    /*
    	Register appSetting to be used
    */

    application.register('appSetting:main', appSetting, {
      instantiate: false
    });
    /*
    	Inject the initializer to route, component, and template
    */

    application.inject('route', 'appSetting', 'appSetting:main');
    application.inject('component', 'appSetting', 'appSetting:main');
    application.inject('template', 'appSetting', 'appSetting:main');
  }

  var _default = {
    name: 'global-app',
    initialize: initialize
  };
  _exports.default = _default;
});