define("client/pods/login/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      if (this.session.get('isAuthenticated')) {
        this.transitionTo('start');
      }
    }
  });

  _exports.default = _default;
});