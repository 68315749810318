define("client/initializers/optmm-global", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    var optmmSetting = {
      euserInfo: null,
      companynum: null,
      sitenum: null,
      employeenum: null
    };
    application.register('optmmSetting:main', optmmSetting, {
      instantiate: false
    });
    application.inject('route', 'optmmSetting', 'optmmSetting:main');
    application.inject('component', 'optmmSetting', 'optmmSetting:main');
    application.inject('template', 'optmmSetting', 'optmmSetting:main');
  }

  var _default = {
    name: 'optmm-global',
    initialize: initialize
  };
  _exports.default = _default;
});