define("client/models/goodreceivedetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    itemlotid: _emberData.default.attr('string'),
    qty: _emberData.default.attr('number'),
    uomconvtosku: _emberData.default.attr('string'),
    valuepriceperqty: _emberData.default.attr('number'),
    valuebasecurr: _emberData.default.attr('number'),
    valueconv: _emberData.default.attr('number'),
    type: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    status: _emberData.default.attr('string'),
    selectitem: _emberData.default.attr('string'),
    selectmanufacturer: _emberData.default.attr('string'),
    //relation
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    goodreceivenum: _emberData.default.belongsTo('goodreceived', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    manufacturernum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    itemlotnum: _emberData.default.belongsTo('itemlot', {
      async: true
    }),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    currnum: _emberData.default.belongsTo('currency', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }) // inventorybalancenum: DS.belongsTo('inventorybalance', {async: true})

  });

  _exports.default = _default;
});