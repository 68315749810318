define("client/helpers/string-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringEqual = stringEqual;
  _exports.default = void 0;

  function stringEqual(params
  /*, hash*/
  ) {
    if (Ember.isEmpty(params[0])) {
      return true;
    } else {
      if (params[0] === params[1]) {
        return true;
      } else {
        return false;
      }
    }
  }

  var _default = Ember.Helper.helper(stringEqual);

  _exports.default = _default;
});