define("client/models/workorder", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    workorderid: _emberData.default.attr('string'),
    sequence: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    instruction: _emberData.default.attr('string'),
    workorderextralocation: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    schedulestart: _emberData.default.attr('date'),
    schedulefinish: _emberData.default.attr('date'),
    targetstart: _emberData.default.attr('date'),
    targetfinish: _emberData.default.attr('date'),
    actualstart: _emberData.default.attr('date'),
    actualfinish: _emberData.default.attr('date'),
    istask: _emberData.default.attr('boolean'),
    plan: _emberData.default.attr('boolean'),
    actual: _emberData.default.attr('boolean'),
    needreturn: _emberData.default.attr('boolean'),
    isdowntime: _emberData.default.attr('boolean'),
    // if it is task
    taskid: _emberData.default.attr('string'),
    estimationduration: _emberData.default.attr('number'),
    scheduleduration: _emberData.default.attr('number'),
    duration: _emberData.default.attr('number'),
    memo: _emberData.default.attr('string'),
    generatefrom: _emberData.default.attr('string'),
    isdelete: _emberData.default.attr('boolean'),
    // Task Dependence
    dependencytype: _emberData.default.attr('string'),
    //relationship
    dependenceto: _emberData.default.belongsTo("workorder", {
      inverse: 'children2',
      async: true
    }),
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    sitenum: _emberData.default.belongsTo("site", {
      async: true
    }),
    locationnum: _emberData.default.belongsTo("location", {
      async: true
    }),
    assetnum: _emberData.default.belongsTo("asset", {
      async: true
    }),
    workorderpriority: _emberData.default.belongsTo("workorderpriority", {
      async: true
    }),
    workordertypenum: _emberData.default.belongsTo("workordertype", {
      async: true
    }),
    classificationnum: _emberData.default.belongsTo("classification", {
      async: true
    }),
    failureclassnum: _emberData.default.belongsTo("failureclass", {
      async: true
    }),
    failureproblemnum: _emberData.default.belongsTo("failureproblem", {
      async: true
    }),
    servicerequestnum: _emberData.default.belongsTo("servicerequest", {
      async: true
    }),
    // assetcharacteristicnum: DS.belongsTo("assetcharacteristic",{ async: true}),

    /*
    Generate Relation
    */
    prevmaintainnum: _emberData.default.belongsTo("prevmaintain", {
      async: true
    }),
    masterpmnum: _emberData.default.belongsTo("masterpm", {
      async: true
    }),
    assetmeterreadnum: _emberData.default.belongsTo("assetmeterread", {
      async: true
    }),
    jobplannum: _emberData.default.belongsTo("jobplan", {
      async: true
    }),
    assetconditionmonitornum: _emberData.default.belongsTo("assetconditionmonitor", {
      async: true
    }),
    pmgeneratesettingnum: _emberData.default.belongsTo("pmgeneratesetting", {
      async: true
    }),
    routenum: _emberData.default.belongsTo("route", {
      async: true
    }),

    /*
    Generate Relation End
    */
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    reportedby: _emberData.default.belongsTo('employee', {
      async: true
    }),
    supervisor: _emberData.default.belongsTo('employee', {
      async: true
    }),
    inspector: _emberData.default.belongsTo('employee', {
      async: true
    }),
    vendor: _emberData.default.belongsTo("vendor", {
      async: true
    }),
    workorderparentnum: _emberData.default.belongsTo("workorder", {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany("workorder", {
      inverse: 'workorderparentnum',
      async: true
    }),
    children2: _emberData.default.hasMany("workorder", {
      inverse: 'dependenceto',
      async: true
    })
  });

  _exports.default = _default;
});