define("client/models/inventoryserial", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventoryserialid: _emberData.default.attr('string'),
    RFID: _emberData.default.attr('string'),
    used: _emberData.default.attr('boolean'),
    reserved: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relation
    inventorybalancenum: _emberData.default.belongsTo('inventorybalance', {
      async: true
    }),
    inventoryitemnum: _emberData.default.belongsTo('inventory', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    racknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    binnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    inventorybalancedetailnum: _emberData.default.belongsTo('inventorybalancedetail', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    }) //inventoryusagenum: DS.belongsTo('inventoryusage', {async: true})

  });

  _exports.default = _default;
});