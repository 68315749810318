define("client/helpers/contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var contains = function contains(params) {
    return params[1].indexOf(params[0].toString()) > -1;
  };

  var _default = Ember.Helper.helper(contains);

  _exports.default = _default;
});