define("client/initializers/leaflet-assets", ["exports", "ember-leaflet/initializers/leaflet-assets"], function (_exports, _leafletAssets) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _leafletAssets.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _leafletAssets.initialize;
    }
  });
});