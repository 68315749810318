define("client/helpers/string-contains", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stringContains = stringContains;
  _exports.default = void 0;

  function stringContains(params
  /*, hash*/
  ) {
    if (Ember.isEmpty(params[0])) {
      return true;
    } else {
      if (params[0].indexOf(params[1]) !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  var _default = Ember.Helper.helper(stringContains);

  _exports.default = _default;
});