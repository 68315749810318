define("client/pods/components/w3office-forms/filter-modal/component", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('isError', false);
    },
    actions: {
      addFilter: function addFilter() {
        var _this$get$ = this.get("filterColumns")[0],
            columnName = _this$get$.columnName,
            label = _this$get$.label,
            placeholder = _this$get$.placeholder,
            relationColumnName = _this$get$.relationColumnName,
            type = _this$get$.type; //create new selectedFilter object

        this.get('selectedFilters').addObject({
          columnName: columnName,
          label: label,
          operator: this.get("filterColumns")[0].operator[0],
          placeholder: placeholder,
          relationColumnName: relationColumnName,
          type: type,
          value: null,
          value2: null
        });
      },
      applyFilter: function applyFilter() {
        var _this = this;

        //Send filter values to be processed on route
        this.set('isError', false);
        this.get('selectedFilters').forEach(function (filter) {
          if (!_this.get("isError")) {
            if (filter.operator === "><") {
              if (filter.type === "number" && parseInt(filter.value, 10) >= parseInt(filter.value2, 10)) {
                _this.set("isError", true);

                _this.set("errorMessage", "Value 1 must be less than Value 2 on ".concat(filter.label));
              } else if (filter.type === "date") {// use moment comparison
                // 
              }
            } else {
              if (filter.type !== 'boolean' && Ember.isEmpty(filter.value)) {
                _this.set('isError', true);

                _this.set("errorMessage", "Value field on ".concat(filter.label, " must not be empty"));
              }
            }
          }
        });

        if (this.get('isError') === false) {
          this.applyFilter();
          (0, _jquery.default)('#entries-filter').modal('hide');
        }
      },
      deleteFilter: function deleteFilter(filter) {
        //delete selected filter
        this.get('selectedFilters').removeObject(filter);
      }
    }
  });

  _exports.default = _default;
});