define("client/helpers/min-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.minOne = minOne;
  _exports.default = void 0;

  function minOne(params, hash) {
    var temp = parseInt(params[0]);
    return temp -= 1;
  }

  var _default = Ember.Helper.helper(minOne);

  _exports.default = _default;
});