define("client/models/pmgeneratesetting", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    pmgeneratesettingid: _emberData.default.attr('string'),
    value: _emberData.default.attr('number'),
    frequency: _emberData.default.attr('string'),
    frequencytype: _emberData.default.attr('string'),
    generationtype: _emberData.default.attr('string'),
    startdate: _emberData.default.attr('date'),
    enddate: _emberData.default.attr('date'),
    createdAt: _emberData.default.attr('date'),
    updatedAt: _emberData.default.attr('date'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    companynum: _emberData.default.belongsTo("company", {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo("organization", {
      async: true
    }),
    prevmaintainnum: _emberData.default.belongsTo("prevmaintain", {
      async: true
    }),
    jobplannum: _emberData.default.belongsTo("jobplan", {
      async: true
    }),
    assetnum: _emberData.default.belongsTo("asset", {
      async: true
    }),
    locationnum: _emberData.default.belongsTo("location", {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});