define("client/components/loading-spokes", ["exports", "client/components/loading-component"], function (_exports, _loadingComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _loadingComponent.default.extend({});

  _exports.default = _default;
});