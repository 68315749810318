define("client/models/classification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    classificationid: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    classificationparentnum: _emberData.default.belongsTo('classification', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('classification', {
      inverse: 'classificationparentnum',
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});