define("client/models/inventorylot", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    inventorylotid: _emberData.default.attr('string'),
    lotqty: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    //relationships
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    inventoryitemnum: _emberData.default.belongsTo('inventory', {
      async: true
    }),
    lotuom: _emberData.default.belongsTo('uom', {
      async: true
    }),
    manufacturernum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  }); //- Validate for Empty Filling of companynum,sitenum,location,itemnum


  _exports.default = _default;
});