define("client/models/item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    itemid: _emberData.default.attr('string'),
    itemname: _emberData.default.attr('string'),
    itemtype: _emberData.default.attr('string'),
    isrotating: _emberData.default.attr('boolean'),
    isactive: _emberData.default.attr('boolean'),
    issparepart: _emberData.default.attr('boolean'),
    islot: _emberData.default.attr('boolean'),
    isserial: _emberData.default.attr('boolean'),
    msds: _emberData.default.attr('string'),
    iscondition: _emberData.default.attr('boolean'),
    averagecost: _emberData.default.attr('number'),
    standardcost: _emberData.default.attr('number'),
    lastissuedate: _emberData.default.attr('date'),
    lastissuetrx: _emberData.default.attr('string'),
    lastissuetrxnum: _emberData.default.attr('number'),
    lastreceiptdate: _emberData.default.attr('date'),
    lastreceipttrx: _emberData.default.attr('string'),
    lastreceiptnum: _emberData.default.attr('number'),
    costingtype: _emberData.default.attr('string'),
    // AVERAGECOST, STANDARDCOST, FIFO
    runningcost: _emberData.default.attr('boolean'),
    adjustedac: _emberData.default.attr('boolean'),
    adjustedsc: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    itemcategorynum: _emberData.default.belongsTo('itemcategory', {
      async: true
    }),
    sku: _emberData.default.belongsTo('uom', {
      async: true
    }),
    // sku
    onissueuom: _emberData.default.belongsTo('uom', {
      async: true
    }),
    onorderuom: _emberData.default.belongsTo('uom', {
      async: true
    }),
    metergroupnum: _emberData.default.belongsTo('metergroup', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});