define("client/models/inventorybalancedetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    detailtransactiondate: _emberData.default.attr('date'),
    sourcetype: _emberData.default.attr('string'),
    //char(15) , adjustmentdetail
    sourcenum: _emberData.default.attr('number'),
    //for now take from inventoryadjustmentdetailnum
    parentsourcetype: _emberData.default.attr('string'),
    parentsourcenum: _emberData.default.attr('number'),
    parenttransactiondate: _emberData.default.attr('date'),
    debitcredittype: _emberData.default.attr('string'),
    //char(1)
    qty: _emberData.default.attr('number'),
    //float
    value: _emberData.default.attr('number'),
    //money
    onhandqty: _emberData.default.attr('number'),
    //float
    referencenum: _emberData.default.attr('number'),
    //used if debitcredit == c. filled with inventorybalancedetailnum
    istransfer: _emberData.default.attr('boolean'),
    relationtype: _emberData.default.attr('string'),
    relationnum: _emberData.default.attr('number'),
    averagecost: _emberData.default.attr('number'),
    standardcost: _emberData.default.attr('number'),
    isdelete: _emberData.default.attr('boolean'),
    //relationship
    inventorybalancenum: _emberData.default.belongsTo('inventorybalance', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    vendornum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    manufacturernum: _emberData.default.belongsTo('vendor', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});