define("client/models/inventoryusagedetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    sequence: _emberData.default.attr('number'),
    qty: _emberData.default.attr('number'),
    returnqty: _emberData.default.attr('number'),
    shippedqty: _emberData.default.attr('number'),
    inventorylotid: _emberData.default.attr('string'),
    // char(15)
    isdelete: _emberData.default.attr('boolean'),
    usage: _emberData.default.attr('string'),
    usagetype: _emberData.default.attr('string'),
    refid: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    //relation
    invusagenum: _emberData.default.belongsTo('inventoryusage', {
      async: true
    }),
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    fromsitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    fromlocationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    fromracknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    frombinnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    assetnum: _emberData.default.belongsTo('asset', {
      async: true
    }),
    uomnum: _emberData.default.belongsTo('uom', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    conditionnum: _emberData.default.belongsTo('condition', {
      async: true
    }),
    tositenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    tolocationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    toracknum: _emberData.default.belongsTo('rack', {
      async: true
    }),
    tobinnum: _emberData.default.belongsTo('bin', {
      async: true
    }),
    workordernum: _emberData.default.belongsTo('workorder', {
      async: true
    }),
    inventorylotnum: _emberData.default.belongsTo('inventorylot', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});