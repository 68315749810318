define("client/pods/components/w3o-users-roles/role-paging/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      changeLimit: function changeLimit() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.changeLimit();
      },
      prevList: function prevList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.prevList();
      },
      nextList: function nextList() {
        // Created by : Michael
        // Created date : 12 April 2016
        // Current Programmer : Michael
        // Edit date : 12 April 2016
        // Note : 
        this.nextList();
      },
      doEdit: function doEdit(id) {
        this.set('roleId', id);
        this.set('editRole', true);
      },
      cancelEditRole: function cancelEditRole() {
        this.set('roleId', null);
        this.set('editRole', false);
      },
      editRoleName: function editRoleName(role) {
        this.editRoleName(role);
      },
      setDeletedRole: function setDeletedRole(role) {
        this.set('deletedRole', role);
      },
      deleteRole: function deleteRole() {
        this.deleteRole(this.get('deletedRole'));
      }
    }
  });

  _exports.default = _default;
});