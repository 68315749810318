define("client/initializers/file-uploader-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.inject('route', 'fileUploader', 'service:file-uploader');
    application.inject('component', 'fileUploader', 'service:file-uploader');
  }

  var _default = {
    name: 'file-uploader-service',
    initialize: initialize
  };
  _exports.default = _default;
});