define("client/pods/components/w3office-forms/advanced-components/popup-list/component", ["exports", "jquery", "client/mixins/w3office-forms/base-component"], function (_exports, _jquery, _baseComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_baseComponent.default, {
    authObject: Ember.inject.service('session'),

    /* Set Unique Id for popup-list modal dialog */
    popupListModalId: Ember.computed('elementId', function () {
      return this.get('elementId') + '-popupModal';
    }),
    isLoadFieldFilter: Ember.computed('field.loadFieldFilter', function () {
      return this.get('field').get('loadFieldFilter');
    }),
    isLoadFieldContents: Ember.computed('field.loadFieldContents', function () {
      return this.get('field').get('loadFieldContents');
    }),
    isSkeleton: Ember.computed('isLoadFieldFilter', 'isLoadFieldContents', function () {
      if (this.get('isLoadFieldFilter') || this.get('isLoadFieldContents')) {
        return true;
      } else {
        return false;
      }
    }),
    listHeader: Ember.computed('field.listHeader', function () {
      return this.get('field').get('listHeader');
    }),
    containerLabel: Ember.computed('field.containerLabel', function () {
      return this.get('field').get('containerLabel');
    }),
    activeFilterColumn: Ember.computed('field.activeFilterColumn', function () {
      return this.get('field').get('activeFilterColumn');
    }),
    entryList: Ember.computed('field.entryList', function () {
      return this.get('field').get('entryList');
    }),
    totalPage: Ember.computed('field.totalPage', function () {
      return this.get('field').get('totalPage');
    }),
    loadingData: Ember.computed('field.loadingData', function () {
      return this.get('field').get('loadingData');
    }),
    arrayOfSelected: Ember.computed('field.arrayOfSelected', function () {
      return this.get('field').get('arrayOfSelected');
    }),
    arrayOfSelectedId: Ember.computed('field.arrayOfSelectedId', function () {
      return this.get('field').get('arrayOfSelectedId');
    }),

    /* Set Field to show component or not based on field config */
    init: function init() {
      this._super.apply(this, arguments);
      /*
      * 'entryShow' means 'containerLabel' 
      */


      this.setProperties({
        currentLimit: 10,
        currentPage: 1,
        disablePopulateEntryShow: false,
        lastSortParam: 'id',
        loadingData: true,
        totalPage: 0
      });
      this.get('field').setProperties({
        loadFieldFilter: true,
        loadFieldContents: true,
        arrayOfSelected: Ember.A(),
        arrayOfSelectedId: []
      });

      if (this.get('environment') === 'view' || this.get('environment') === 'delete' || this.get('environment') === 'task') {
        (0, _jquery.default)('.popup').addClass('disabled');
      }
      /* Generate Listing Dependencies if Popup List is enabled */


      if (!this.get('authoring')) {
        if (!Ember.isEmpty(this.get('field')) && (this.get('environment') === 'create' || this.get('environment') === 'edit')) {
          if (this.get('field').id) {
            this.fieldActionGateway(this, {
              actionName: 'getFieldGroupContent'
            });
            this.fieldActionGateway(this, {
              actionName: 'populateFieldFilter'
            });
          } else {
            this.get('field').setProperties({
              loadFieldContents: false,
              loadFieldFilter: false
            });
          }
        } else {
          this.fieldActionGateway(this, {
            actionName: 'populateEntryShow'
          });
        }
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (!this.get('disablePopulateEntryShow') && !this.get('isDestroying')) {
        if (!Ember.isEmpty(this.get('field').get('entryContainer'))) {
          this.set('containerValue', this.get('field').get('entryContainer'));
          this.fieldActionGateway(this, {
            actionName: 'populateEntryShow'
          });
        } else {
          this.set('containerValue', null);
          this.set('containerLabel', null);
        }
      }
    },

    /*
    	Reset condition to original condition
    */
    resetList: function resetList() {
      /*
      	Remove container for filter values on listing reset
      */
      this.setProperties({
        activeFilterColumn: null,
        activeFilterValue: null,
        currentPage: 1,
        loadingData: true
      });
      this.get('field').get('conditionContainer')['skip'] = 0;
      this.fieldActionGateway(this, {
        actionName: 'fetchRecords'
      });
    },

    /*
    	Limit Records to original condition
    */
    changeEntryLimit: function changeEntryLimit(limit) {
      this.setProperties({
        currentLimit: limit,
        currentPage: 1,
        loadingData: true
      });
      this.get('field').get('conditionContainer')['skip'] = 0;
      this.get('field').get('conditionContainer')['limit'] = limit;
      this.fieldActionGateway(this, {
        actionName: 'fetchRecords'
      });
    },
    actions: {
      /*
      	Everytime popup listing is shown, list condition is also reset
      */
      showList: function showList() {
        this.resetList();
        (0, _jquery.default)('#' + this.get('popupListModalId')).modal({
          backdrop: 'static',
          keyboard: false
        });
      },

      /*
      	Set picked entry on popup list to show on input field
      */
      saveEntry: function saveEntry(entry) {
        var _this = this;

        this.setProperties({
          containerValue: entry.id,
          disablePopulateEntryShow: true,
          loadingData: true
        });
        entry.content.forEach(function (_entry) {
          if (_entry.columnName === _this.get('field').get('columnToShow')) {
            _this.set('containerLabel', _entry.value);
          }
        });
        var valueColumn = Ember.isEmpty(this.get("field.valueColumn")) ? "id" : this.get("field.valueColumn");
        this.setEntryContainer(this.get('field'), entry.rawEntry[valueColumn]);
        Ember.run.later(this, function () {
          this.set('disablePopulateEntryShow', false);
        }, 500);
      },

      /*
      	Delete Entry
      */
      deleteEntry: function deleteEntry() {
        this.set('containerValue', null);
        this.setEntryContainer(this.get('field'), null);
      },

      /*
      	Add additional condition
      */
      filterEntries: function filterEntries() {
        this.setProperties({
          currentPage: 1,
          loadingData: true
        });
        this.get('field').get('conditionContainer')['skip'] = 0;
        this.fieldActionGateway(this, {
          actionName: 'fetchRecords'
        });
      },

      /*
      	Apply Entries Sorting
      */
      sortEntries: function sortEntries(header) {
        this.setProperties({
          currentPage: 1,
          loadingData: true
        });
        this.get('field').get('conditionContainer')['skip'] = 0;
        this.fieldActionGateway(this, {
          actionName: 'fetchRecords'
        });
      },

      /*
      	Action to enable resetList callback from dialog component
      */
      resetList: function resetList() {
        this.resetList();
      },
      changeEntryLimit: function changeEntryLimit(limit) {
        this.changeEntryLimit(limit);
      },
      nextList: function nextList() {
        if (this.get('currentPage') < this.get('totalPage')) {
          this.setProperties({
            currentPage: this.get('currentPage') + 1,
            loadingData: true
          });
          this.get('field').get('conditionContainer').skip += this.get('currentLimit');
          this.fieldActionGateway(this, {
            actionName: 'fetchRecords'
          });
        }
      },
      prevList: function prevList() {
        if (this.get('currentPage') > 1) {
          this.setProperties({
            currentPage: this.get('currentPage') - 1,
            loadingData: true
          });
          this.get('field').get('conditionContainer').skip -= this.get('currentLimit');
          this.fieldActionGateway(this, {
            actionName: 'fetchRecords'
          });
        }
      },
      pushToArrayOfSelected: function pushToArrayOfSelected(entry) {
        var _this2 = this;

        // Must do this before call setEntryContainer()
        this.set("disablePopulateEntryShow", true); // set isRecordChecked to TRUE or FALSE

        var selectedObject = {
          id: entry.id
        };
        entry.content.forEach(function (_entry) {
          if (_entry.columnName === _this2.get('field').get('columnToShow')) {
            selectedObject.label = _entry.value;
          }
        });

        if (entry.get("isRecordChecked")) {
          var removedIndex = undefined;
          this.get('field').get('arrayOfSelected').forEach(function (selectedObject, index) {
            if (selectedObject.id === entry.id) removedIndex = index;
          });

          if (removedIndex !== undefined) {
            if (this.get('field').get('arrayOfSelected').get('length') === 1) {
              this.get('field').get('arrayOfSelected').clear();
            } else {
              this.get('field').get('arrayOfSelected').removeAt(removedIndex);
            }
          }

          this.get('field').get('arrayOfSelectedId').removeObject(entry.id);
        } else {
          this.get('field').get('arrayOfSelected').pushObject(selectedObject);
          this.get('field').get('arrayOfSelectedId').pushObject(entry.id);
        }

        entry.set("isRecordChecked", !entry.get('isRecordChecked')); // this.setEntryContainer(this.get('field'), JSON.stringify(this.get('field').get('arrayOfSelectedId')) );

        this.setEntryContainer(this.get('field'), this.get('field').get('arrayOfSelectedId').toString());
        Ember.run.later(this, function () {
          this.set('disablePopulateEntryShow', false);
        }, 500);
      },
      removeFromArrayOfSelected: function removeFromArrayOfSelected(selectedObject, index) {
        // Must do this before call setEntryContainer()
        this.set("disablePopulateEntryShow", true);
        this.get('field').get('arrayOfSelected').removeAt(index);
        this.get('field').get('arrayOfSelectedId').removeObject(selectedObject.id); // this.setEntryContainer(this.get('field'), JSON.stringify(this.get('field').get('arrayOfSelectedId')) );

        this.setEntryContainer(this.get('field'), this.get('field').get('arrayOfSelectedId').toString());
        Ember.run.later(this, function () {
          this.set('disablePopulateEntryShow', false);
        }, 500);
      }
    }
  });

  _exports.default = _default;
});