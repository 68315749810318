define("client/authenticators/authy", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant"], function (_exports, _oauth2PasswordGrant) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    serverTokenEndpoint: '/api/v1/auths/login2',
    // serverTokenRevocationEndpoint: '/revoke'
    routing: Ember.inject.service('-routing'),
    authObject: Ember.inject.service('session'),
    authenticate: function authenticate(identification, password, authCode, scope) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {
          'grant_type': 'password',
          username: identification,
          password: password,
          authCode: authCode
        };

        var serverTokenEndpoint = _this.get('serverTokenEndpoint');

        var scopesString = Ember.makeArray(scope).join(' ');

        if (!Ember.isEmpty(scopesString)) {
          data.scope = scopesString;
        }

        _this.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            var expiresAt = _this._absolutizeExpirationTime(response['expires_in']);

            _this._scheduleAccessTokenRefresh(response['expires_in'], expiresAt, response['refresh_token']);

            if (!Ember.isEmpty(expiresAt)) {
              response = Ember.merge(response, {
                'expires_at': expiresAt
              });
            }

            resolve(response);
          });
        }, function (xhr) {
          Ember.run(null, reject, xhr.responseJSON || xhr.responseText);
        });
      });
    },
    _refreshAccessToken: function _refreshAccessToken(expiresIn, refreshToken) {
      var _this2 = this;

      var self = this;
      var data = {
        'grant_type': 'refresh_token',
        'refresh_token': refreshToken
      };
      var serverTokenEndpoint = this.get('serverTokenEndpoint');
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.makeRequest(serverTokenEndpoint, data).then(function (response) {
          Ember.run(function () {
            expiresIn = response['expires_in'] || expiresIn;
            refreshToken = response['refresh_token'] || refreshToken;

            var expiresAt = _this2._absolutizeExpirationTime(expiresIn);

            var data = Ember.merge(response, {
              'expires_in': expiresIn,
              'expires_at': expiresAt,
              'refresh_token': refreshToken
            });

            _this2._scheduleAccessTokenRefresh(expiresIn, null, refreshToken);

            _this2.trigger('sessionDataUpdated', data);

            resolve(data);
          });
        }, function (xhr, status, error) {
          Ember.Logger.warn("Access token could not be refreshed - server responded with ".concat(error, "."));
          reject(self.get('routing').transitionTo('users-roles.session-expired'));
        });
      });
    }
  });

  _exports.default = _default;
});