define("client/pods/process-builder/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G4TjKrqB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col px-2 mt-5 pt-3 d-block d-md-none\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[8],[0,\"Please use device with bigger screen to use this App\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col px-1 px-md-4 mt-5 pt-2 pt-md-4 d-none d-md-block\"],[8],[0,\"\\n\\t\"],[1,[22,\"outlet\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "client/pods/process-builder/template.hbs"
    }
  });

  _exports.default = _default;
});