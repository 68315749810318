define("client/models/servicerequest", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    servicerequestid: _emberData.default.attr('string'),
    referencestuff: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    problemexplanation: _emberData.default.attr('string'),
    leaddays: _emberData.default.attr('number'),
    estimationresolveddate: _emberData.default.attr('date'),
    status: _emberData.default.attr('string'),
    workorderstatus: _emberData.default.attr('string'),
    requestedby: _emberData.default.attr('string'),
    requesteddate: _emberData.default.attr('date'),
    requesteremail: _emberData.default.attr('string'),
    requesterphone: _emberData.default.attr('string'),
    takendate: _emberData.default.attr('date'),
    takenby: _emberData.default.attr('string'),
    takenphone: _emberData.default.attr('string'),
    cancelby: _emberData.default.attr('string'),
    incustomer: _emberData.default.attr('boolean'),
    inspection: _emberData.default.attr('string'),
    //relationn
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    asset_asset: _emberData.default.belongsTo('asset', {
      async: true
    }),
    optmm_site: _emberData.default.belongsTo('site', {
      async: true
    }),
    optmm_location: _emberData.default.belongsTo('location', {
      async: true
    }),
    servicedesk_category: _emberData.default.belongsTo('servicecategory', {
      async: true
    }),
    servicedesk_type: _emberData.default.belongsTo('servicetype', {
      async: true
    }),
    servicedesk_problem: _emberData.default.belongsTo('serviceproblem', {
      async: true
    }),
    servicedesk_solution: _emberData.default.belongsTo('servicesolution', {
      async: true
    }),
    // servicedesk_srresponsibility: DS.belongsTo('serviceresponsibility',{async: true}),
    isdelete: _emberData.default.attr('boolean'),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});