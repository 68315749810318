define("client/pods/components/w3office-forms/bootstrap-card/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['card', 'clickable', 'py-3'],
    classNameBindings: ['bgLight'],
    bgLight: false,
    init: function init() {
      this._super();

      if (this.get('index') % 2 == 0) {
        this.set('bgLight', true);
      } else {
        this.set('bgLight', false);
      }
    },
    records: Ember.computed('content', 'headers', function () {
      var _this = this;

      var records = Ember.A();
      this.get('headers').forEach(function (header) {
        var tempObj = {
          id: null,
          content: null,
          isBoolean: false
        };

        if (header.relationColumnName && _this.get('content')[header.id]) {
          tempObj.id = header.label;
          tempObj.content = _this.get('content')[header.id].get(header.relationColumnName);
        } else if (header.isCalculatedFieldAlias) {
          tempObj.id = header.label;
          tempObj.content = _this.get('content')[header.isCalculatedFieldAlias];
        } else if (header.id) {
          tempObj.id = header.label;
          tempObj.content = _this.get('content')[header.id];
        } else if (_this.get('content').content) {
          // For popup-list component, from popup-list-modal
          var filteredRecord = _this.get('content').content.filter(function (col) {
            return col.columnName === header.columnName;
          });

          tempObj.id = header.content;
          tempObj.content = filteredRecord[0].value;
        }

        if (typeof tempObj.content === 'boolean') {
          tempObj.isBoolean = true;
        }

        records.push(tempObj);
      });
      return records;
    }),

    /*
    	Needed for bootstrap-card in popup-list-modal
    */
    click: function click() {
      if (typeof this.actionToView === 'function') {
        this.actionToView(this.get('content'));
      }
    }
  });

  _exports.default = _default;
});