define("client/models/inventory", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    onhandqty: _emberData.default.attr('number'),
    reservedqty: _emberData.default.attr('number'),
    qtyavail: _emberData.default.attr('number'),
    // onorderqty: DS.attr('number'),
    // onissueqty: DS.attr('number'),
    isactive: _emberData.default.attr('boolean'),
    isdelete: _emberData.default.attr('boolean'),
    minlevel: _emberData.default.attr('number'),
    maxlevel: _emberData.default.attr('number'),
    islottype: _emberData.default.attr('boolean'),
    isserialtype: _emberData.default.attr('boolean'),
    barcode: _emberData.default.attr('string'),
    averagecost: _emberData.default.attr('number'),
    standardcost: _emberData.default.attr('number'),
    lastissuedate: _emberData.default.attr('date'),
    lastissuetrx: _emberData.default.attr('string'),
    lastissuetrxnum: _emberData.default.attr('number'),
    lastreceiptdate: _emberData.default.attr('date'),
    lastreceipttrx: _emberData.default.attr('string'),
    lastreceiptnum: _emberData.default.attr('number'),
    runningcost: _emberData.default.attr('boolean'),
    adjustedac: _emberData.default.attr('boolean'),
    adjustedsc: _emberData.default.attr('boolean'),
    //relationships
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    itemnum: _emberData.default.belongsTo('item', {
      async: true
    }),
    sku: _emberData.default.belongsTo('uom', {
      async: true
    }),
    // onorderuom: DS.belongsTo('uom', {async: true}),
    // onissueuom: DS.belongsTo('uom', {async: true}),
    // manufacturernum: DS.belongsTo('vendor', {async: true}),
    // vendornum: DS.belongsTo('vendor', {async: true}),
    locationnum: _emberData.default.belongsTo('location', {
      async: true
    }),
    sitenum: _emberData.default.belongsTo('site', {
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  }); //- Validate for Empty Filling of companynum,sitenum,location,itemnum


  _exports.default = _default;
});