define("client/mixins/w3office-forms/form-process-common", ["exports", "axios"], function (_exports, _axios) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    fetchFormProcess: function fetchFormProcess(dynamicParams) {
      /**
       * dynamicParams is an Object: {
       *  formId,
       *  initialization,
       *  entryId,
       *  finished: false
       * }
       * 
       * if provide 'formId' and 'initialization, will return 'processConnections'
       * if provide 'entryId' and 'finished', will return 'instanceTracks'
       */
      var url = "".concat(this.store.adapterFor('application').get('namespace'), "/formprocesses/fetchFormProcess");
      return (0, _axios.default)({
        method: 'POST',
        url: url,
        data: dynamicParams
      }).catch(function (err) {
        console.log('err');
        console.log(err);
      }).then(function (response) {
        /**
         * response.data is an Object: {
         *  processConnections,
         *  instanceTracks
         * }
         */
        return response.data;
      });
    },

    /*
    * Pool Users of Group of an Activity
    * called from checkProcessConnecitonList() or changing on selectedFormprocessChanged()
    */
    poolUserGroupListInitialization: function poolUserGroupListInitialization(formProcess) {
      var _this = this;

      var payload = {
        processID: formProcess.process.processId,
        submitterId: this.get('authObject.session.content.authenticated.user.id')
      };

      if (this.controller.get('form').get('resourceAccessEnabled')) {
        payload.resourceAccessEnabled = true;
        payload.organizationnum = this.get('formContainer.organization');
        payload.companynum = this.get('formContainer.company');
      }

      $.ajax({
        type: "POST",
        url: this.store.adapterFor('application').get('namespace') + '/processactivities/poolUserGroupListInitialization',
        data: JSON.stringify(payload),
        contentType: 'application/json',
        success: function success(response) {
          if (response.errorMessage) {
            _this.controller.setProperties({
              errorMessagePoolUserGroup: response.errorMessage,
              submitButtonDisabled: true
            });
          } else {
            _this.controller.setProperties({
              errorMessagePoolUserGroup: null,
              submitButtonDisabled: false,
              userGroupList: response.users
            });
          }
        }
      });
    },
    actions: {
      selectedFormprocessChanged: function selectedFormprocessChanged(param) {
        this.controller.set('selectedFormprocess', param);

        if (param) {
          this.poolUserGroupListInitialization(param);
        }
      },

      /*
      	Start selected form process
      */
      startProcess: function startProcess(formprocess, selectedUser) {
        var controller = this.controller,
            objRecord = {};
        objRecord[this.controller.get('form').get('modelName')] = this.controller.get('objRecord');
        var url = this.store.adapterFor('application').get('namespace') + '/processactivities/startProcess';
        this.controller.get('processConnectionList').removeObject(formprocess);
        /*
        	Call start process API
        */

        var processData = {
          entry: JSON.stringify(objRecord),
          entryName: controller.get('form').get('modelName'),
          processID: formprocess.process.processId,
          userID: this.get('formContainer.userHolder'),
          formID: controller.get('form').get('id'),
          url: this.store.adapterFor('application').get('mainHost'),
          organizationnum: this.get('formContainer.organization'),
          companynum: this.get('formContainer.company'),
          selectedAssigneeUser: selectedUser.id
        };
        $.ajax({
          type: "POST",
          url: url,
          data: processData,
          success: function success(res) {
            controller.setProperties({
              message: "Process ".concat(formprocess.process.title, " is started"),
              succeed: true,
              triggered: true
            }); // later(this, function() {
            // 	controller.set('triggered', false);
            // }, 5000);
          },
          error: function error(xhr, status, _error) {
            controller.setProperties({
              message: 'Error ' + status + ': ' + _error,
              succeed: false,
              triggered: true
            }); // later(this, function() {
            // 	controller.set('triggered', false);
            // }, 5000);
          }
        });
      }
    }
  });

  _exports.default = _default;
});