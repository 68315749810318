define("client/helpers/number-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.numberEqual = numberEqual;
  _exports.default = void 0;

  function numberEqual(params
  /*, hash*/
  ) {
    if (parseInt(params[0]) === parseInt(params[1])) {
      return true;
    } else {
      return false;
    }
  }

  var _default = Ember.Helper.helper(numberEqual);

  _exports.default = _default;
});