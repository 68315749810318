define("client/models/servicecomment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    shortdescription: _emberData.default.attr('string'),
    comment: _emberData.default.attr('string'),
    usertext: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    suggestionstatus: _emberData.default.attr('string'),
    lateststatus: _emberData.default.attr('string'),
    // relation
    organizationnum: _emberData.default.belongsTo('organization', {
      async: true
    }),
    companynum: _emberData.default.belongsTo('company', {
      async: true
    }),
    servicedesk_servicerequest: _emberData.default.belongsTo('servicerequest', {
      async: true
    }),
    servicedesk_srcomment: _emberData.default.belongsTo('servicecomment', {
      inverse: 'children',
      async: true
    }),
    children: _emberData.default.hasMany('servicecomment', {
      inverse: 'servicedesk_srcomment',
      async: true
    }),
    createdBy: _emberData.default.belongsTo('user', {
      async: true
    }),
    updatedBy: _emberData.default.belongsTo('user', {
      async: true
    })
  });

  _exports.default = _default;
});