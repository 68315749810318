define("client/pods/w3office-forms/form/process/route", ["exports", "jquery", "client/mixins/w3o-users-roles/check-user-role"], function (_exports, _jquery, _checkUserRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_checkUserRole.default, {
    beforeModel: function beforeModel() {
      this._super();
    },
    renderTemplate: function renderTemplate() {
      this._super();
    },
    model: function model() {
      return this.modelFor('w3office-forms/form');
    },
    setupController: function setupController(controller, model) {
      controller.set("currentUser", this.store.findRecord("user", this.get("authObject.data.authenticated.user.id")));
      var self = this; //Set controller properties

      controller.set('processConnection', null);
      controller.set('processConnectionQuery', this.store.query('formprocess', {
        where: {
          form: model.get('id')
        }
      }));
      controller.set('processConnectionList', null);
      controller.set('form', model);
      controller.set('succeed', true);
      controller.set('triggered', false);
      controller.set('selectedProcessConnection', null);
      controller.set('taskConfigQuery', null);
      controller.set('taskConfigList', []);
      controller.set('activityList', null); //Populate Process List

      this.store.query('process', {
        where: {
          app: model.get('appID')
        }
      }).then(function (processes) {
        controller.set('processList', processes);
      }); //Populate Initialization Type

      controller.set('initializationList', [{
        name: 'Only a part of the Process',
        value: ''
      }, {
        name: 'On Form Submission',
        value: 'form-submission'
      }, {
        name: 'Manually Start on Entry View',
        value: 'manual-button'
      }, {
        name: 'On API Form Submission',
        value: 'api-form-submission'
      }]); //Populate Connection Process

      controller.get('processConnectionQuery').then(function (formprocesses) {
        controller.set('processConnectionList', formprocesses.toArray());
      });
      this.store.query('field', {
        where: {
          form: self.controller.get('form').get('id')
        }
      }).then(function (fields) {
        controller.set('fieldList', fields);
      });
    },
    actions: {
      createProcessConnection: function createProcessConnection() {
        var controller = this.controller;
        controller.get('processConnectionList').pushObject(this.store.createRecord('formprocess', {
          form: controller.get('form'),
          createdBy: controller.get("currentUser")
        }));
      },
      saveProcessConnections: function saveProcessConnections() {
        var arrPromise = Ember.A();
        var controller = this.controller;
        controller.get('processConnectionList').forEach(function (processConnection) {
          processConnection.set("updatedBy", controller.get("currentUser"));
          arrPromise.push(processConnection.save());
        });
        Ember.RSVP.all(arrPromise).then(function () {
          controller.set('message', 'Process Connections are persisted.');
          controller.set('succeed', true);
          controller.set('triggered', true); // later(this, () => {
          // 	controller.set('triggered', false);
          // }, 5000);
        }, function (reason) {
          controller.set('message', 'There is some problem on persisting Process Connections. ' + reason);
          controller.set('succeed', false);
          controller.set('triggered', true); // later(this, () => {
          // 	controller.set('triggered', false);
          // }, 5000);
        });
      },
      configProcessConnection: function configProcessConnection(processConnection) {
        var self = this;

        if (!Ember.isEmpty(processConnection.get('id'))) {
          this.controller.set('taskConfigQuery', this.store.query('fieldtaskconfig', {
            where: {
              formProcess: processConnection.get('id')
            }
          }));
          self.store.query('processversion', {
            where: {
              process: processConnection.get('process').get('id'),
              latest: true
            }
          }).then(function (processversions) {
            self.store.query('processactivity', {
              where: {
                processversion: processversions.get('firstObject').get('id')
              }
            }).then(function (activities) {
              self.controller.set('activityList', activities);
              self.controller.get('taskConfigQuery').then(function (taskconfigs) {
                self.controller.set('selectedProcessConnection', processConnection);
                self.controller.set('taskConfigList', taskconfigs.toArray());
                (0, _jquery.default)('#process-props').modal();
              });
            });
          });
        } else {
          self.controller.set('message', 'Please persist process connection first.');
          self.controller.set('succeed', false);
          self.controller.set('triggered', true); // later(this, function() {
          // 	self.controller.set('triggered', false);
          // }, 5000);
        }
      },
      deleteProcessConnection: function deleteProcessConnection(processConnection) {
        this.controller.get('processConnectionList').removeObject(processConnection);
        processConnection.deleteRecord();
        processConnection.save();
      },
      addTaskConfig: function addTaskConfig() {
        var controller = this.controller;
        controller.get('taskConfigList').addObject(this.store.createRecord('fieldtaskconfig', {
          formProcess: controller.get('selectedProcessConnection'),
          createdBy: controller.get("currentUser")
        }));
      },
      deleteTaskConfig: function deleteTaskConfig(taskConfig) {
        this.controller.get('taskConfigList').removeObject(taskConfig);
        taskConfig.deleteRecord();
        taskConfig.save();
      },
      saveTaskConfig: function saveTaskConfig() {
        var arrPromise = [];
        var controller = this.controller;
        this.controller.get('taskConfigList').forEach(function (taskConfig) {
          taskConfig.set("updatedBy", controller.get("currentUser"));
          arrPromise.push(taskConfig.save());
        });
        Ember.RSVP.all(arrPromise).then(function () {
          (0, _jquery.default)('#process-props').modal('hide');
        });
      }
    }
  });

  _exports.default = _default;
});